<template>
  
    <v-dialog v-model="dialog" persistent width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="isNew"
          rounded
          v-on="on"
          v-bind="attrs"
          color="green"
          depressed
          class="white--text"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("add test") }}
        </v-btn>
        <v-icon v-if="!isNew" @click="fetchData" v-on="on" v-bind="attrs" color="blue">mdi-pen</v-icon>
       
       
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>{{ isNew ? $t("add test") : $t('edit') }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-text-field
                outlined
                dense
                :label="$t('Test Name')"
                v-model="test.name"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-autocomplete
                outlined
                dense
                :label="$t('subject name')"
                :items="getSubjects"
                v-model="test.subject_id"
                item-text="name"
                item-value="id"
                :loading="subjectsLoading"
                @input="selectPartition"
              ></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-text-field   :label="$t('date')" type="date" 
                            v-model="test.date" outlined
            dense >
              </v-text-field>
            </v-col>
            <v-col md="6">
              <v-select
                v-model="test.subject_partition_id"  
                color="#757575" 
                :label="$t('Partition Title')"
                :items="partition"
                item-text="name"
                item-value="id"
                outlined
                dense
                @input="selectPercent(test.subject_partition_id)"
            ></v-select>
            </v-col>
            <v-col md="6">
              <v-text-field
                outlined
                dense
                :label="$t('Test Mark')"
                v-model="test.max_mark"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-row>
                <v-col md="10">
                  <v-text-field
                outlined
                dense
                :label="$t('Percent')"
                v-model="test.percent"
                type="number"
              ></v-text-field>
                </v-col>
              <v-col><h3 class="mt-2" >%</h3></v-col>
              </v-row>
            </v-col>
            <v-col md="6">
              <v-checkbox
                  :label="$t('Declared')"
                  class="mx-4 mb-4"
                  v-model="test.status"
                  hide-details
            ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            depressed
            class="white--text"
            @click="submit"
            :loading="btnLoading"
            >{{ isNew ? $t("storage") : $t('edit')  }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="clearData">
                    {{ $t('cancel') }}
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      dialog: false,
      dateDialog: false,
      subjects: [],
      partitions: [],
      subjectsLoading: false,
      test: {
        subject_partition_id: null,
        status: null,
        percent: null,
        name: "",
        subject_id: null,
        min_mark: 0,
        max_mark: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
    };
  },
  props: {
    // either grade_id or edu-class_id
    id: {
      type: [String, Number],
    },
    // either grade or edu-class
    idType: {
      type: String,
    },
    isNew: {
            type: Boolean,
            default: true,
        },
      item: {
        type: Object,
        default: () => {
                return {};
            },
      }  
  },
  computed: {
    ...mapGetters({
      getSubjects: "gradeEducationClassShared/getSubjects",
      partition: "subjects/getPartitions",
    }),
  },
  methods: {
    ...mapActions({
      fetchSubjects: "gradeEducationClassShared/fetchSubjects",
      fetchPartitions: "subjects/fetchPartitions",

    }),
  async  selectPartition(){
      this.fetchPartitions({
        subject_id: this.test.subject_id,
        edu_class_id: this.id,
      });
      
    },
    selectPercent(id){
      console.log(id);
      this.partitions = this.partition;
      if (this.idType == "edu-class") 
      for(let i=0;i<this.partitions.length;i++){

        if(id == this.partitions[i].id)
        {
          this.test.percent = this.partitions[i].rest;
        }
      }
    },
    async submit() {
      try {
        if(this.test.status){
          this.test.status = "announced"; 
        } 
        if(!this.test.status){
          this.test.status = null; 
        } 
        if (this.idType == "edu-class") {
          this.test.edu_class_id = this.id;
        } else {
          this.test.grade_id = this.id;
        }
        this.btnLoading = true;
        if(this.isNew) {
         let res =  await axios.post("/test", this.test);
          if(res.status==200)
          this.$Notifications(
            this.$t('add success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        } else{
         let res = await axios.put(`/test/${this.item.id}`, this.test);
         if(res.status==200)
          this.$Notifications(
            this.$t('edit success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        }
        
        this.$emit("testSubmitted");
       this.clearData();
      } catch (err) {
      } finally {
        this.btnLoading = false;
      
      }
    },
    clearData(){
      this.test.percent = null;
      this.test.status = null;
      this.test.subject_partition_id = null;
      this.test.name = "";
      this.test.subject_id = null;
      this.test.min_mark = null;
      this.test.max_mark = null;
      this.test.date = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
        this.dialog = false;
    },
   async fetchData(){
    
      this.test.percent = this.item.percent;
      
      this.test.subject_partition_id = this.item.subject_partition.id;
      this.test.name = this.item.name;
      this.test.subject_id = this.item.subject.id;
      this.test.min_mark = this.item.min_mark;
      this.test.max_mark = this.item.max_mark;
      this.test.date = this.item.date;
      if(this.item.status=='un_announced'){
        this.test.status = null;
      } else this.test.status = this.item.status;
      this.fetchPartitions({
        subject_id: this.test.subject_id,
        edu_class_id: this.id,
      });
    }
  },
  async created() {
 
   
    this.subjectsLoading = true;
    this.fetchSubjects().finally((_) => {
      this.subjectsLoading = false;
    });

  },
};
</script>

<style>
</style>