<template>
  <div>
    <v-container class="py-10">
      <v-row>
        <v-col md="12" class="d-flex justify-end pl-5" v-if="returnAbility('note:store')">
          <app-add-note @noteSubmitted="fetchData(1)" :id="id" :idType="idType"></app-add-note>
        </v-col>
        <v-col md="12">
          <app-base-table :tableOptions="tableOptions" :flaggedItems="flaggedItemsData" @re-fetch-data="fetchData"
            @re-fetch-paginated-data="fetchData($event)" serverRoute="/note" :enableDelete="displayDeleteIcon">
            <template slot="editFeature" slot-scope="{ row }">
              <app-edit-note v-if="returnAbility('note:update')" :id="id" :idType="idType" :recordData="row"
                @noteEdited="fetchData(1)"></app-edit-note>
            </template>
          </app-base-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import addNote from "./addNote.vue";
import editNote from "./editNote.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    appEditNote: editNote,
    appAddNote: addNote,
  },
  data() {
    return {
      flaggedItemsData: ["registration_record_id", "type", "id"],
    };
  },
  props: {
    // either grade_id or edu-class_id
    id: {
      type: [String, Number],
    },
    // either grade or edu-class
    idType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      tableOptions: "notes/getTableOptions"
    }),
  },
  methods: {
    ...mapActions({
      fetchNotes: "notes/fetchNotes",
    }),
    fetchData(page) {
      let params = {
        page,
      };
      if (this.idType == "grade") {
        params.grade_id = this.id;
      }
      if (this.idType == "edu-class") {
        params.edu_class_id = this.id;
      }
      console.log("-1");
      this.fetchNotes(params);
      console.log("1?");
    },
  },
  created() {
    console.log("created fam");
    if (this.returnAbility("note:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style>

</style>